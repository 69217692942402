@import "src/assets/styles/others.module";

.PieWheel {
	$widthHeightSize: pxToVwMaxed(328px);
	display: flex;
	overflow: hidden;
	position: relative;
	align-items: center;
	flex-direction: column;
	width: $widthHeightSize;
	justify-content: flex-end;
	height: pxToVwMaxed(330px);
	&__pointer {
		top: 0;
		z-index: 1;
		position: absolute;
		height: pxToVwMaxed(32px);
	}
	&__container {
		overflow: hidden;
		position: relative;
		border-radius: 50%;
		align-self: flex-end;
		width: $widthHeightSize;
		height: $widthHeightSize;
		&:before {
			content: '';
			width: 100%;
			height: 100%;
			border-radius: 50%;
			position: absolute;
			background-color: var(--primaryDefault);
		}
	}
	&__slice {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		overflow: hidden;
		position: absolute;
		border-radius: 50%;
		justify-content: center;
		&__contentContainer {
			z-index: 1;
			width: 100%;
			height: 50%;
			display: flex;
			position: absolute;
			align-items: center;
			justify-content: center;
		}
		&__inner {
			width: 100%;
			height: 100%;
			display: block;
		}
		&__circle {
			top: 15%;
			z-index: 1;
			display: flex;
			position: absolute;
			border-radius: 50%;
			align-items: center;
			justify-content: center;
			width: pxToVwMaxed(30px);
			height: pxToVwMaxed(30px);
			transform: translateY(-50%);
			background-color: var(--warningDefault);
			@include s-title-bold-20();
		}
	}
}
