@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Joti+One&display=swap');
@import "@hangry/react-ui-kit/dist/index.css";
@import "others.module";

:root {
	--ui-kit-design-width: #{stripUnit($screenWidthMobile)};
	--ui-kit-scale-max-width: #{stripUnit($screenWidthMobile)};
}

html, body, #root {
	min-height: 100vh;
	position: relative;
}
