@import "src/assets/styles/others.module";

html, body, #root {
	background-color: var(--cautionBackground);
}

.PieSliceLabelContainer {
	transform: rotateZ(-90deg) translateX(40%);
	p {
		text-align: center;
		font-family: 'Joti One', cursive;
	}
	&__labelColorYellow {
		color: var(--warningDefault);
	}
	&__labelColorRed {
		color: var(--primaryDefault);
	}
	&__labelColorWhite {
		color: var(--textPrimaryWhite);
	}
	&__labelSmall {
		font-size: pxToVwMaxed(12px);
	}
	&__labelMedium {
		font-size: pxToVwMaxed(20px);
	}
}
