@import "src/assets/styles/others.module";

.Home {
	z-index: 1;
	display: flex;
	position: relative;
	min-height: inherit;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	padding: 0 pxToVw(16px);
	&__disableDesktopLabel {
		display: block;
		text-align: center;
		color: var(--textPrimary);
		@include s-title-bold-18();
		&:nth-child(2) {
			margin-top: pxToVwMaxed(8px);
		}
	}
	&__disableButton {
		width: pxToVw(100px);
		margin-top: pxToVw(24px);
	}
	&__header {
		top: 0;
		width: 100%;
		position: absolute;
		height: pxToVwMaxed(56px);
		&__center {
			margin: auto;
			height: 100%;
			display: flex;
			align-items: center;
			box-sizing: border-box;
			padding: 0 pxToVwMaxed(16px);
			max-width: pxToVwMaxed($mobileBreakPoint);
			img {
				height: pxToVwMaxed(28px);
			}
		}
	}
	&__body {
		display: flex;
		align-items: center;
		flex-direction: column;
		&__gameLogo {
			width: pxToVwMaxed(136px);
			margin-bottom: pxToVwMaxed(36px);
		}
		&__pieWheel {
			position: relative;
		}
		&__startButton {
			z-index: 1;
			position: absolute;
			width: pxToVwMaxed(96px);
			bottom: pxToVwMaxed(328px);
			transition-duration: 250ms;
			transform: translateY(460%);
			&--hidden {
				opacity: 0;
				visibility: hidden;
			}
		}
		&__footer {
			display: block;
			text-align: center;
			color: var(--textTertiary);
			margin-top: pxToVwMaxed(36px);
			@include s-caption-medium-12();
		}
	}
}
