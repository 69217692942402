@import "@hangry/react-ui-kit/dist/index.css";
@import "src/assets/styles/others.module";

.AyamKoplo {
	&__disableText {
		color: #C01B62;
	}
	&__background {
		left: 50%;
		width: 100vw;
		height: 100vh;
		overflow: hidden;
		position: absolute;
		background-size: cover;
		transform: translateX(-50%);
		background-position: center;
		max-width: $mobileBreakPoint;
	}
	&__imageLogo {
		width: pxToVwMaxed(251px);
		margin-bottom: pxToVwMaxed(30px);
	}
	&__wheelContainer {
		&:before {
			background-color: #C01B62;
		}
	}
	&__pieSliceLabelContainer {
		display: flex;
		align-items: center;
		flex-direction: column;
		transform: translateY(-25%);
		img {
			width: pxToVwMaxed(64px);
			height: pxToVwMaxed(64px);
			margin-bottom: pxToVwMaxed(4px);
		}
		p {
			text-align: center;
			font-size: pxToVwMaxed(10px);
			color: var(--neutralPrimary);
			max-width: pxToVwMaxed(100px);
			line-height: pxToVwMaxed(11px);
			font-family: 'Lilita One', cursive;
		}
	}
	&__button {
		background-color: #D4E836 !important;
		p {
			color: #C01B62;
		}
	}
	&__footer {
		color: #C01B62;
	}
}
