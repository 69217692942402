@import "../../assets/styles/others.module";

.ResultModal {
	top: 0;
	z-index: 1;
	width: 100vw;
	height: 100vh;
	display: flex;
	position: fixed;
	&__background {
		opacity: 0;
		display: block;
		width: inherit;
		height: inherit;
		position: absolute;
		background-color: rgba(0, 0, 0, 0.8);
	}
	&__container {
		z-index: 1;
		width: 100%;
		margin: auto;
		display: flex;
		align-items: center;
		flex-direction: column;
		transform: translateY(-40px);
		max-width: pxToVwMaxed(328px);
		&__surpriseIllustration {
			position: relative;
			width: pxToVwMaxed(258px);
			bottom: pxToVwMaxed(-40px);
		}
		&__card {
			width: 100%;
			overflow: hidden;
			position: relative;
			border-radius: pxToVwMaxed(8px);
		}
		&__headTail {
			&:first-child {
				padding-top: pxToVwMaxed(24px);
			}
			&:last-child {
				padding-bottom: pxToVwMaxed(24px);
			}
		}
		// Voucher Prize
		&__labelContainer {
			box-sizing: border-box;
			padding: pxToVwMaxed(16px);
			background-color: var(--neutralPrimary);
			p {
				text-align: center;
			}
			&__title {
				color: var(--textSecondary);
				margin-bottom: pxToVwMaxed(2px);
				@include s-caption-medium-12();
			}
			&__content {
				color: var(--textPrimary);
				@include heading-h6-18();
			}
		}
		&__separator {
			width: 100%;
			display: flex;
			height: pxToVwMaxed(16px);
			&__transparentCurve {
				$mainSize: pxToVwMaxed(16px);
				display: block;
				overflow: hidden;
				height: $mainSize;
				position: relative;
				width: pxToVwMaxed(8px);
				&:after {
					top: 50%;
					content: '';
					width: $mainSize;
					height: $mainSize;
					border-radius: 50%;
					position: absolute;
					right: pxToVwMaxed(-4px);
					transform: translateY(-50%);
					border: pxToVwMaxed(4px) solid var(--neutralPrimary);
				}
				&:last-child {
					transform: rotateY(180deg);
				}
			}
			&__dashContainer {
				flex: 1;
				display: flex;
				box-sizing: border-box;
				padding: 0 pxToVwMaxed(8px);
				background-color: var(--neutralPrimary);
				hr {
					width: 100%;
					border: none;
					height: pxToVwMaxed(1px);
					background-size: pxToVwMaxed(16px) pxToVwMaxed(1px);
					background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 60%, transparent 60%, transparent 100%);
				}
			}
		}
		&__voucherCode {
			box-sizing: border-box;
			padding: pxToVwMaxed(16px);
			background-color: var(--neutralPrimary);
			&__title {
				text-align: center;
				color: var(--textTertiary);
				margin-bottom: pxToVwMaxed(4px);
				@include s-footer-medium-10();
			}
			&__contentContainer {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: row-reverse;
				p {
					z-index: 1;
					position: relative;
					color: var(--primaryDefault);
					@include heading-h6-18();
					&:before {
						opacity: 0;
						width: 100%;
						z-index: -1;
						content: '';
						height: 100%;
						position: absolute;
						transition-duration: 250ms;
						background-color: var(--warningBackground);
					}
				}
				button {
					padding: 0;
					border: none;
					cursor: pointer;
					width: pxToVwMaxed(24px);
					height: pxToVwMaxed(24px);
					margin-left: pxToVwMaxed(2px);
					background-color: transparent;
					svg {
						color: var(--textPrimary);
						font-size: pxToVwMaxed(12px);
					}
				}
				button:active ~ p:before {
					opacity: 1;
				}
			}
		}
		&__instruction {
			display: flex;
			flex-direction: column;
			padding: pxToVwMaxed(16px);
			background-color: var(--neutralPrimary);
			&__inner {
				position: relative;
				hr {
					margin: 0;
					height: 90%;
					border: none;
					position: absolute;
					left: pxToVwMaxed(16px);
					width: pxToVwMaxed(1px);
					background-size: pxToVwMaxed(8px) pxToVwMaxed(8px);
					background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 60%, transparent 60%, transparent 100%);
				}
				div {
					display: flex;
					&:nth-child(2) {
						margin-bottom: pxToVwMaxed(16px);
					}
					span {
						z-index: 1;
						display: flex;
						flex-shrink: 0;
						position: relative;
						border-radius: 50%;
						align-items: center;
						justify-content: center;
						width: pxToVwMaxed(32px);
						height: pxToVwMaxed(32px);
						color: var(--primaryDefault);
						margin-right: pxToVwMaxed(12px);
						background-color: var(--neutralPrimary);
						border: pxToVwMaxed(1px) solid var(--neutralBorder);
						@include s-heading-h6-18();
					}
					p {
						color: var(--textSecondary);
						@include s-caption-regular-12();
					}
				}
			}
		}
		// Merch Prize
		&__singleTicket {
			margin: auto;
			position: relative;
			max-width: pxToVwMaxed(312px);
			@mixin big-circle {
				top: 50%;
				z-index: -1;
				content: '';
				position: absolute;
				border-radius: 50%;
				width: pxToVwMaxed(16px);
				height: pxToVwMaxed(16px);
				transform: translateY(-50%);
				border: pxToVwMaxed(100px) solid var(--neutralPrimary);
			}
			&:before {
				@include big-circle();
				right: pxToVwMaxed(-116px);
			}
			&:after {
				@include big-circle();
				left: pxToVwMaxed(-116px);
			}
		}
		&__downloadAppButton {
			width: 100%;
			margin-top: pxToVwMaxed(16px) !important;
		}
	}
}
