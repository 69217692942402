@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lilita+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Joti+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap);
.pieWheel_PieWheel__23nsg{display:flex;overflow:hidden;position:relative;align-items:center;flex-direction:column;width:min(328 / var(--ui-kit-design-width) * 100vw,
    328px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );justify-content:flex-end;height:min(330 / var(--ui-kit-design-width) * 100vw,
    330px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.pieWheel_PieWheel__pointer__3LNMT{top:0;z-index:1;position:absolute;height:min(32 / var(--ui-kit-design-width) * 100vw,
    32px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.pieWheel_PieWheel__container__3Cale{overflow:hidden;position:relative;border-radius:50%;align-self:flex-end;width:min(328 / var(--ui-kit-design-width) * 100vw,
    328px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );height:min(328 / var(--ui-kit-design-width) * 100vw,
    328px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.pieWheel_PieWheel__container__3Cale:before{content:"";width:100%;height:100%;border-radius:50%;position:absolute;background-color:var(--primaryDefault)}.pieWheel_PieWheel__slice__ctpLY{top:0;left:0;width:100%;height:100%;display:flex;overflow:hidden;position:absolute;border-radius:50%;justify-content:center}.pieWheel_PieWheel__slice__contentContainer__3zBzj{z-index:1;width:100%;height:50%;display:flex;position:absolute;align-items:center;justify-content:center}.pieWheel_PieWheel__slice__inner__2-RGe{width:100%;height:100%;display:block}.pieWheel_PieWheel__slice__circle__bdnlb{top:15%;z-index:1;display:flex;position:absolute;border-radius:50%;align-items:center;justify-content:center;width:min(30 / var(--ui-kit-design-width) * 100vw,
    30px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );height:min(30 / var(--ui-kit-design-width) * 100vw,
    30px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );transform:translateY(-50%);background-color:var(--warningDefault);font-family:Inter,sans-serif;font-weight:700;font-size:min(20 / var(--ui-kit-design-width) * 100vw,
    20px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );line-height:min(28 / var(--ui-kit-design-width) * 100vw,
    28px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}
.resultModal_ResultModal__2T4MV{top:0;z-index:1;width:100vw;height:100vh;display:flex;position:fixed}.resultModal_ResultModal__background__JuQCz{opacity:0;display:block;width:inherit;height:inherit;position:absolute;background-color:rgba(0,0,0,.8)}.resultModal_ResultModal__container__1-y3e{z-index:1;width:100%;margin:auto;display:flex;align-items:center;flex-direction:column;transform:translateY(-40px);max-width:min(328 / var(--ui-kit-design-width) * 100vw,
    328px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.resultModal_ResultModal__container__surpriseIllustration__r4IUt{position:relative;width:min(258 / var(--ui-kit-design-width) * 100vw,
    258px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );bottom:max(-40 / var(--ui-kit-design-width) * 100vw,
    -40px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.resultModal_ResultModal__container__card__-8cD6{width:100%;overflow:hidden;position:relative;border-radius:min(8 / var(--ui-kit-design-width) * 100vw,
    8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.resultModal_ResultModal__container__headTail__3lHIQ:first-child{padding-top:min(24 / var(--ui-kit-design-width) * 100vw,
    24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.resultModal_ResultModal__container__headTail__3lHIQ:last-child{padding-bottom:min(24 / var(--ui-kit-design-width) * 100vw,
    24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.resultModal_ResultModal__container__labelContainer__qQAJu{box-sizing:border-box;padding:min(16 / var(--ui-kit-design-width) * 100vw,
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );background-color:var(--neutralPrimary)}.resultModal_ResultModal__container__labelContainer__qQAJu p{text-align:center}.resultModal_ResultModal__container__labelContainer__title__3abAX{color:var(--textSecondary);margin-bottom:min(2 / var(--ui-kit-design-width) * 100vw,
    2px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );font-family:Inter,sans-serif;font-weight:500;font-size:min(12 / var(--ui-kit-design-width) * 100vw,
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );line-height:min(18 / var(--ui-kit-design-width) * 100vw,
    18px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.resultModal_ResultModal__container__labelContainer__content__FPZRu{color:var(--textPrimary);font-family:Inter,sans-serif;font-weight:700;font-size:18px;line-height:26px}.resultModal_ResultModal__container__separator__3om1W{width:100%;display:flex;height:min(16 / var(--ui-kit-design-width) * 100vw,
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.resultModal_ResultModal__container__separator__transparentCurve__n4r15{display:block;overflow:hidden;height:min(16 / var(--ui-kit-design-width) * 100vw,
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );position:relative;width:min(8 / var(--ui-kit-design-width) * 100vw,
    8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.resultModal_ResultModal__container__separator__transparentCurve__n4r15:after{top:50%;content:"";width:min(16 / var(--ui-kit-design-width) * 100vw,
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );height:min(16 / var(--ui-kit-design-width) * 100vw,
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );border-radius:50%;position:absolute;right:max(-4 / var(--ui-kit-design-width) * 100vw,
    -4px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );transform:translateY(-50%);border:min(4 / var(--ui-kit-design-width) * 100vw,
    4px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) solid var(--neutralPrimary)}.resultModal_ResultModal__container__separator__transparentCurve__n4r15:last-child{transform:rotateY(180deg)}.resultModal_ResultModal__container__separator__dashContainer__27pJV{flex:1 1;display:flex;box-sizing:border-box;padding:0 min(8 / var(--ui-kit-design-width) * 100vw,
    8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );background-color:var(--neutralPrimary)}.resultModal_ResultModal__container__separator__dashContainer__27pJV hr{width:100%;border:none;height:min(1 / var(--ui-kit-design-width) * 100vw,
    1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );background-size:min(16 / var(--ui-kit-design-width) * 100vw,
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) min(1 / var(--ui-kit-design-width) * 100vw,
    1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );background-image:linear-gradient(90deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 60%, transparent 60%, transparent 100%)}.resultModal_ResultModal__container__voucherCode__2baGD{box-sizing:border-box;padding:min(16 / var(--ui-kit-design-width) * 100vw,
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );background-color:var(--neutralPrimary)}.resultModal_ResultModal__container__voucherCode__title__8bjbx{text-align:center;color:var(--textTertiary);margin-bottom:min(4 / var(--ui-kit-design-width) * 100vw,
    4px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );font-family:Inter,sans-serif;font-weight:500;font-size:min(10 / var(--ui-kit-design-width) * 100vw,
    10px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );line-height:min(12 / var(--ui-kit-design-width) * 100vw,
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.resultModal_ResultModal__container__voucherCode__contentContainer__3mBb-{display:flex;align-items:center;justify-content:center;flex-direction:row-reverse}.resultModal_ResultModal__container__voucherCode__contentContainer__3mBb- p{z-index:1;position:relative;color:var(--primaryDefault);font-family:Inter,sans-serif;font-weight:700;font-size:18px;line-height:26px}.resultModal_ResultModal__container__voucherCode__contentContainer__3mBb- p:before{opacity:0;width:100%;z-index:-1;content:"";height:100%;position:absolute;transition-duration:250ms;background-color:var(--warningBackground)}.resultModal_ResultModal__container__voucherCode__contentContainer__3mBb- button{padding:0;border:none;cursor:pointer;width:min(24 / var(--ui-kit-design-width) * 100vw,
    24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );height:min(24 / var(--ui-kit-design-width) * 100vw,
    24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );margin-left:min(2 / var(--ui-kit-design-width) * 100vw,
    2px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );background-color:transparent}.resultModal_ResultModal__container__voucherCode__contentContainer__3mBb- button svg{color:var(--textPrimary);font-size:min(12 / var(--ui-kit-design-width) * 100vw,
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.resultModal_ResultModal__container__voucherCode__contentContainer__3mBb- button:active~p:before{opacity:1}.resultModal_ResultModal__container__instruction__11OxY{display:flex;flex-direction:column;padding:min(16 / var(--ui-kit-design-width) * 100vw,
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );background-color:var(--neutralPrimary)}.resultModal_ResultModal__container__instruction__inner__jEs6A{position:relative}.resultModal_ResultModal__container__instruction__inner__jEs6A hr{margin:0;height:90%;border:none;position:absolute;left:min(16 / var(--ui-kit-design-width) * 100vw,
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );width:min(1 / var(--ui-kit-design-width) * 100vw,
    1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );background-size:min(8 / var(--ui-kit-design-width) * 100vw,
    8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) min(8 / var(--ui-kit-design-width) * 100vw,
    8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );background-image:linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 60%, transparent 60%, transparent 100%)}.resultModal_ResultModal__container__instruction__inner__jEs6A div{display:flex}.resultModal_ResultModal__container__instruction__inner__jEs6A div:nth-child(2){margin-bottom:min(16 / var(--ui-kit-design-width) * 100vw,
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.resultModal_ResultModal__container__instruction__inner__jEs6A div span{z-index:1;display:flex;flex-shrink:0;position:relative;border-radius:50%;align-items:center;justify-content:center;width:min(32 / var(--ui-kit-design-width) * 100vw,
    32px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );height:min(32 / var(--ui-kit-design-width) * 100vw,
    32px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );color:var(--primaryDefault);margin-right:min(12 / var(--ui-kit-design-width) * 100vw,
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );background-color:var(--neutralPrimary);border:min(1 / var(--ui-kit-design-width) * 100vw,
    1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) solid var(--neutralBorder);font-family:Inter,sans-serif;font-weight:700;font-size:min(18 / var(--ui-kit-design-width) * 100vw,
    18px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );line-height:min(26 / var(--ui-kit-design-width) * 100vw,
    26px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.resultModal_ResultModal__container__instruction__inner__jEs6A div p{color:var(--textSecondary);font-family:Inter,sans-serif;font-weight:400;font-size:min(12 / var(--ui-kit-design-width) * 100vw,
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );line-height:min(18 / var(--ui-kit-design-width) * 100vw,
    18px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.resultModal_ResultModal__container__singleTicket__2x90w{margin:auto;position:relative;max-width:min(312 / var(--ui-kit-design-width) * 100vw,
    312px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.resultModal_ResultModal__container__singleTicket__2x90w:before{top:50%;z-index:-1;content:"";position:absolute;border-radius:50%;width:min(16 / var(--ui-kit-design-width) * 100vw,
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );height:min(16 / var(--ui-kit-design-width) * 100vw,
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );transform:translateY(-50%);border:min(100 / var(--ui-kit-design-width) * 100vw,
    100px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) solid var(--neutralPrimary);right:max(-116 / var(--ui-kit-design-width) * 100vw,
    -116px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.resultModal_ResultModal__container__singleTicket__2x90w:after{top:50%;z-index:-1;content:"";position:absolute;border-radius:50%;width:min(16 / var(--ui-kit-design-width) * 100vw,
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );height:min(16 / var(--ui-kit-design-width) * 100vw,
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );transform:translateY(-50%);border:min(100 / var(--ui-kit-design-width) * 100vw,
    100px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) solid var(--neutralPrimary);left:max(-116 / var(--ui-kit-design-width) * 100vw,
    -116px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.resultModal_ResultModal__container__downloadAppButton__23CyV{width:100%;margin-top:min(16 / var(--ui-kit-design-width) * 100vw,
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) !important}
.baseComponent_Home__3-5Jz{z-index:1;display:flex;position:relative;min-height:inherit;align-items:center;flex-direction:column;justify-content:center;padding:0 calc(16 / var(--ui-kit-design-width) * 100vw)}.baseComponent_Home__disableDesktopLabel__2oPqO{display:block;text-align:center;color:var(--textPrimary);font-family:Inter,sans-serif;font-weight:700;font-size:min(18 / var(--ui-kit-design-width) * 100vw,
    18px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );line-height:min(26 / var(--ui-kit-design-width) * 100vw,
    26px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.baseComponent_Home__disableDesktopLabel__2oPqO:nth-child(2){margin-top:min(8 / var(--ui-kit-design-width) * 100vw,
    8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.baseComponent_Home__disableButton__JJf_x{width:calc(100 / var(--ui-kit-design-width) * 100vw);margin-top:calc(24 / var(--ui-kit-design-width) * 100vw)}.baseComponent_Home__header__2zygi{top:0;width:100%;position:absolute;height:min(56 / var(--ui-kit-design-width) * 100vw,
    56px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.baseComponent_Home__header__center__2Rehj{margin:auto;height:100%;display:flex;align-items:center;box-sizing:border-box;padding:0 min(16 / var(--ui-kit-design-width) * 100vw,
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );max-width:min(425 / var(--ui-kit-design-width) * 100vw,
    425px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.baseComponent_Home__header__center__2Rehj img{height:min(28 / var(--ui-kit-design-width) * 100vw,
    28px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.baseComponent_Home__body__3H8xJ{display:flex;align-items:center;flex-direction:column}.baseComponent_Home__body__gameLogo__18t0T{width:min(136 / var(--ui-kit-design-width) * 100vw,
    136px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );margin-bottom:min(36 / var(--ui-kit-design-width) * 100vw,
    36px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.baseComponent_Home__body__pieWheel__3sucz{position:relative}.baseComponent_Home__body__startButton__yolbm{z-index:1;position:absolute;width:min(96 / var(--ui-kit-design-width) * 100vw,
    96px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );bottom:min(328 / var(--ui-kit-design-width) * 100vw,
    328px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );transition-duration:250ms;transform:translateY(460%)}.baseComponent_Home__body__startButton--hidden__1UDih{opacity:0;visibility:hidden}.baseComponent_Home__body__footer__1y6wk{display:block;text-align:center;color:var(--textTertiary);margin-top:min(36 / var(--ui-kit-design-width) * 100vw,
    36px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );font-family:Inter,sans-serif;font-weight:500;font-size:min(12 / var(--ui-kit-design-width) * 100vw,
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );line-height:min(18 / var(--ui-kit-design-width) * 100vw,
    18px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}
html,body,#home_root__3ipka{background-color:var(--cautionBackground)}.home_PieSliceLabelContainer__2IDHZ{transform:rotateZ(-90deg) translateX(40%)}.home_PieSliceLabelContainer__2IDHZ p{text-align:center;font-family:"Joti One",cursive}.home_PieSliceLabelContainer__labelColorYellow__2UhMJ{color:var(--warningDefault)}.home_PieSliceLabelContainer__labelColorRed__1Pjs6{color:var(--primaryDefault)}.home_PieSliceLabelContainer__labelColorWhite__179Wb{color:var(--textPrimaryWhite)}.home_PieSliceLabelContainer__labelSmall__1p4y0{font-size:min(12 / var(--ui-kit-design-width) * 100vw,
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.home_PieSliceLabelContainer__labelMedium__y7A7L{font-size:min(20 / var(--ui-kit-design-width) * 100vw,
    20px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}
.koplo_AyamKoplo__disableText__bh8HA{color:#c01b62}.koplo_AyamKoplo__background__1SX0D{left:50%;width:100vw;height:100vh;overflow:hidden;position:absolute;background-size:cover;transform:translateX(-50%);background-position:center;max-width:425px}.koplo_AyamKoplo__imageLogo__3tHY6{width:min(251 / var(--ui-kit-design-width) * 100vw,
    251px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );margin-bottom:min(30 / var(--ui-kit-design-width) * 100vw,
    30px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.koplo_AyamKoplo__wheelContainer__2HErx:before{background-color:#c01b62}.koplo_AyamKoplo__pieSliceLabelContainer__3wtYW{display:flex;align-items:center;flex-direction:column;transform:translateY(-25%)}.koplo_AyamKoplo__pieSliceLabelContainer__3wtYW img{width:min(64 / var(--ui-kit-design-width) * 100vw,
    64px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );height:min(64 / var(--ui-kit-design-width) * 100vw,
    64px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );margin-bottom:min(4 / var(--ui-kit-design-width) * 100vw,
    4px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.koplo_AyamKoplo__pieSliceLabelContainer__3wtYW p{text-align:center;font-size:min(10 / var(--ui-kit-design-width) * 100vw,
    10px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );color:var(--neutralPrimary);max-width:min(100 / var(--ui-kit-design-width) * 100vw,
    100px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );line-height:min(11 / var(--ui-kit-design-width) * 100vw,
    11px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );font-family:"Lilita One",cursive}.koplo_AyamKoplo__button__2sS3I{background-color:#d4e836 !important}.koplo_AyamKoplo__button__2sS3I p{color:#c01b62}.koplo_AyamKoplo__footer__3lzWw{color:#c01b62}
.moonchicken_Moonchicken__disableText__13Pho{color:#f0c543}.moonchicken_Moonchicken__background__3uJul{left:50%;width:100vw;height:100vh;display:flex;overflow:hidden;position:absolute;align-items:flex-end;background-size:cover;transform:translateX(-50%);background-position:center;max-width:425px}.moonchicken_Moonchicken__background__3uJul img{width:100%}.moonchicken_Moonchicken__imageLogo__lKH0_{width:min(216 / var(--ui-kit-design-width) * 100vw,
    216px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );margin-bottom:min(24 / var(--ui-kit-design-width) * 100vw,
    24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.moonchicken_Moonchicken__wheelContainer__MEjQg:before{background-color:#500d94}.moonchicken_Moonchicken__pieSliceLabelContainer__L59br{display:flex;align-items:center;flex-direction:column;transform:translateY(-25%)}.moonchicken_Moonchicken__pieSliceLabelContainer__L59br img{width:min(64 / var(--ui-kit-design-width) * 100vw,
    64px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );height:min(64 / var(--ui-kit-design-width) * 100vw,
    64px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );margin-bottom:min(4 / var(--ui-kit-design-width) * 100vw,
    4px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.moonchicken_Moonchicken__pieSliceLabelContainer__L59br p{text-align:center;font-size:min(10 / var(--ui-kit-design-width) * 100vw,
    10px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );color:var(--neutralPrimary);max-width:min(100 / var(--ui-kit-design-width) * 100vw,
    100px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );line-height:min(11 / var(--ui-kit-design-width) * 100vw,
    11px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );font-family:"Lilita One",cursive}.moonchicken_Moonchicken__button__2r89R{background-color:#060c16 !important}.moonchicken_Moonchicken__button__2r89R p{color:#f0c543}.moonchicken_Moonchicken__footer__2mnzV{color:var(--textPrimaryWhite)}
:export{mobileBreakPoint:425px;tabletBreakPoint:768px;screenWidthMobile:360px;screenWidthDesktop:1440px;commonTransitionDuration:350ms}:root{--ui-kit-design-width: 360;--ui-kit-scale-max-width: 360}html,body,#root{min-height:100vh;position:relative}
html,body{margin:0;padding:0;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}p{margin:0}code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}
