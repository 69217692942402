@use "sass:math";
@import "@hangry/react-ui-kit/dist/typographies.module";
@import "@hangry/react-ui-kit/dist/utils.module";

$mobileBreakPoint: 425px;
$tabletBreakPoint: 768px;
$screenWidthMobile: 360px;
$screenWidthDesktop: 1440px;
$commonTransitionDuration: 350ms;

@function stripUnit($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return math.div($number, $number * 0 + 1);
	}
	@return $number;
}

@mixin disable-user-select() {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

:export {
	mobileBreakPoint: $mobileBreakPoint;
	tabletBreakPoint: $tabletBreakPoint;
	screenWidthMobile: $screenWidthMobile;
	screenWidthDesktop: $screenWidthDesktop;
	commonTransitionDuration: $commonTransitionDuration;
}
