@import "src/assets/styles/others.module";

.Moonchicken {
	&__disableText {
		color: #F0C543;
	}
	&__background {
		left: 50%;
		width: 100vw;
		height: 100vh;
		display: flex;
		overflow: hidden;
		position: absolute;
		align-items: flex-end;
		background-size: cover;
		transform: translateX(-50%);
		background-position: center;
		max-width: $mobileBreakPoint;
		img {
			width: 100%;
		}
	}
	&__imageLogo {
		width: pxToVwMaxed(216px);
		margin-bottom: pxToVwMaxed(24px);
	}
	&__wheelContainer {
		&:before {
			background-color: #500D94;
		}
	}
	&__pieSliceLabelContainer {
		display: flex;
		align-items: center;
		flex-direction: column;
		transform: translateY(-25%);
		img {
			width: pxToVwMaxed(64px);
			height: pxToVwMaxed(64px);
			margin-bottom: pxToVwMaxed(4px);
		}
		p {
			text-align: center;
			font-size: pxToVwMaxed(10px);
			color: var(--neutralPrimary);
			max-width: pxToVwMaxed(100px);
			line-height: pxToVwMaxed(11px);
			font-family: 'Lilita One', cursive;
		}
	}
	&__button {
		background-color: #060C16 !important;
		p {
			color: #F0C543;
		}
	}
	&__footer {
		color: var(--textPrimaryWhite);
	}
}
